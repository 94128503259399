import { genericProjectLayout } from '@atlassian/jira-generic-project-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import { genericProjectSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationSidebarProjectResource } from '@atlassian/jira-router-resources-navigation';
import { newIssueViewTransitionLockInStatusResource } from '@atlassian/jira-router-resources-new-issue-view-transition-lock-in-status/src/services/index.tsx';
import { queuesNavigationItems } from '@atlassian/jira-router-resources-service-desk-queues/src/services/index.tsx';
import { legacyIssueRoute } from '@atlassian/jira-router-routes-legacy-issue-routes';
import { LegacyIssueView } from './ui/legacy/pages/legacy-issue-view/index.tsx';

export const legacyIssueRouteEntry = createEntry(legacyIssueRoute, {
	layout: genericProjectLayout,
	component: LegacyIssueView,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: genericProjectSidebars,
		horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
	},

	resources: [
		...getBusinessProjectResources(),
		...getNavigationSidebarProjectResource(),
		queuesNavigationItems,
		newIssueViewTransitionLockInStatusResource,
		getConsolidationStateResource(),
	],
});
