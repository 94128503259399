import React, { useState, useCallback } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button/standard-button';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ChevronDown from '@atlaskit/icon/glyph/chevron-down';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { getNewEditorLink } from '@atlassian/jira-project-configuration-commons/src/model/utils';
import { WorkflowPublicationStatus } from '../common/types';
import { useChangeToOldEditorSuccessFlag } from '../controllers/change-to-old-editor-success-flag/index.tsx';
import { DefaultWorkflowEditorDialog } from './default-workflow-editor-dialog';
import messages from './messages';

type Props = {
	workflowId: string;
	workflowName: string;
	editMode: 'draft' | 'live';
};

export const WorkflowEditorPreferenceDropdown = ({ workflowId, workflowName, editMode }: Props) => {
	const { formatMessage } = useIntl();
	const [showDialog, setShowDialog] = useState(false);
	const openDialog = () => setShowDialog(true);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// Show flag when show old editor flag is true in session storage
	useChangeToOldEditorSuccessFlag({ openDefaultEditorDialog: openDialog });

	const fireTrackEvent = useCallback(
		(mode: string) => {
			const dropdownItemClickEvent = createAnalyticsEvent({
				action: 'click',
				actionSubject: 'dropdownItem',
			});
			fireUIAnalytics(dropdownItemClickEvent, 'workflowEditorToolbarNewEditorLink', {
				workflowLegacyEditMode: mode === 'draft' ? 'Draft' : 'Live',
			});
		},
		[createAnalyticsEvent],
	);

	return (
		<>
			<DropdownMenu
				testId="workflow-editor-preference-settings.ui.dropdown-menu"
				placement="bottom-end"
				trigger={({ triggerRef, ...props }) => (
					<Button {...props} ref={triggerRef} iconAfter={<ChevronDown label="" />}>
						<LegacyButtonText>{formatMessage(messages.switchEditorButton)}</LegacyButtonText>
					</Button>
				)}
			>
				<DropdownItemGroup>
					<DropdownItem
						href={getNewEditorLink(workflowId)}
						onClick={() => fireTrackEvent(editMode)}
						UNSAFE_shouldDisableRouterLink
					>
						{formatMessage(messages.openInNewEditor)}
					</DropdownItem>
					<DropdownItem onClick={openDialog}>
						{formatMessage(messages.changeDefaultEditor)}
					</DropdownItem>
				</DropdownItemGroup>
			</DropdownMenu>
			{showDialog && (
				<DefaultWorkflowEditorDialog
					workflowName={workflowName}
					workflowId={workflowId}
					workflowPublicationStatus={
						editMode === 'draft' ? WorkflowPublicationStatus.Draft : WorkflowPublicationStatus.Live
					}
					onClose={() => setShowDialog(false)}
					onOpenDefaultEditorDialog={openDialog}
				/>
			)}
		</>
	);
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { DefaultWorkflowEditorDialog } from './default-workflow-editor-dialog';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LegacyButtonText = styled.span({
	fontWeight: 400,
});
