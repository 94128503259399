import React, { type ComponentType, type ReactNode } from 'react';
import { render } from 'react-dom';
import LooselyLazy from 'react-loosely-lazy';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-legacy
import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context';
import {
	EntryPointPlaceholderContext,
	getPlaceholdersFromHTML,
} from '@atlassian/jira-entry-point-placeholder';
import { ErrorBoundaryFlag as ErrorBoundary } from '@atlassian/jira-error-boundary-flag-renderer';
import { initPlatformFeatureFlags } from '@atlassian/jira-feature-flagging';
import getMeta from '@atlassian/jira-get-meta';
import '@atlassian/jira-global-side-effects';
import { setMatchedRoute } from '@atlassian/jira-platform-router-utils/src/common/utils/index.tsx';
import { StrictMode } from '@atlassian/jira-react-strict-mode';
import { Router } from '@atlassian/jira-router-components';
import { JiraRouterLinkConfiguration } from '@atlassian/jira-router-components/src/ui/router-link-config';
import { getRoutes as getLegacyRoutes } from '@atlassian/jira-routes/src/ui/legacy/main.tsx';
import type { PlatformPageType } from '@atlassian/jira-shared-types/src/platform.tsx';
import { getSpaRouterContext } from '@atlassian/jira-spa/src/services/spa-router-context';
import { visualRefreshResolverOverride } from '@atlassian/jira-visual-refresh-rollout/src/resolver-override';
import { createLegacyHistory, matchRoute } from '@atlassian/react-resource-router';

// Trigger async import asap, no need for RLL as SSR renders without too
const pageContainerImport = import(
	/* webpackChunkName: "page-container-v2.nav-v3" */ '@atlassian/jira-page-container-v2/src/ui/legacy-page/main.tsx'
);

const messages = require('language-pack');

type Params = {
	App?: ComponentType<any>;
	containerId?: string;
	onLoad?: () => any;
	pageType?: PlatformPageType;
};

export const renderPageContainer = async ({
	// @ts-expect-error - TS2322 - Type 'null' is not assignable to type 'ComponentType<any>'.
	App = null,
	containerId = 'navigation-app',
	onLoad,
	pageType = 'legacy-page',
}: Params = {}) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const container = document.getElementById(containerId);
	if (container) {
		const { default: PageContainerV2 } = await pageContainerImport;
		LooselyLazy.init({ mode: 'RENDER', crossOrigin: 'anonymous' });

		const tenantContext = getTenantContext_DEPRECATED_DO_NOT_USE();

		global.__NAV_VERSION__ = '3.0.0';

		initPlatformFeatureFlags(visualRefreshResolverOverride);
		const history = createLegacyHistory();
		const routes = getLegacyRoutes();
		const matchedRoute = matchRoute(
			routes,

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.location.pathname,
			// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'Query | undefined'.

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.location.search,
			'',
		);
		setMatchedRoute(matchedRoute?.route, matchedRoute?.match);

		const resourceContext = getSpaRouterContext({ tenantContext });

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const resourceData = window.SKELETON_NAVIGATION;

		const LegacyRouter = ({ children }: { children: ReactNode }) => (
			<Router
				history={history}
				routes={routes}
				resourceContext={resourceContext}
				resourceData={resourceData}
			>
				{children}
			</Router>
		);

		const placeholders = getPlaceholdersFromHTML();

		render(
			<StrictMode>
				<EntryPointPlaceholderContext.Provider value={placeholders}>
					<PageContainerV2
						id="jira.entry.legacy"
						locale={tenantContext.locale}
						baseUrl=""
						messages={messages}
						onLoad={onLoad}
						Router={LegacyRouter}
						routerLinkComponent={JiraRouterLinkConfiguration}
						pageType={pageType}
					>
						<ErrorBoundary id="jira.route-app.legacy-bootstrap">
							{App ? <App /> : null}
						</ErrorBoundary>
					</PageContainerV2>
				</EntryPointPlaceholderContext.Provider>
			</StrictMode>,
			container,
		);
	}
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => {
	const isGlobalSidebarEnabled = getMeta('ajs-global-sidebar-enabled') === 'true';
	if (!isGlobalSidebarEnabled) return;

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (document.getElementById('navigation-app')) {
		renderPageContainer();
	} else {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('DOMContentLoaded', () => renderPageContainer());
	}
};
